/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withTranslation,
  withCustomRouter,
  withUserPreferences,
  withRealEstateInfo,
} from 'components/hoc';
import { isEmptyValue } from 'components/utils';
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';


const RealEstate = (props) => {
    const { realEstateInfo, fd, trObj, fn } = props;

    const getAddress = (address_detail) => {
          const streetDetails = [
            address_detail.street_name,
            address_detail.street_no,
            address_detail.street_no_suffix,
          ]
            .filter((v) => !isEmptyValue(v))
            .join(' ');
      
          return [
            trObj(address_detail.country_detail),
            trObj(address_detail.city_detail),
            streetDetails,
          ]
            .filter((v) => !isEmptyValue(v))
            .join(', ');
        };

  console.log("realEstateInfo", realEstateInfo)
  
  const mapFields = () => {
    return realEstateInfo.data.map((data) => {
      return {
        ...data,
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        address: getAddress(data?.address_detail),
        realestate_type: trObj(data?.realestate_type_detail),
        hab_surf_measure: trObj(data?.hab_surf_measure_detail),
        tot_land_measure: trObj(data?.tot_land_measure_detail),
        cadastral_register: data?.cadastral_register_detail?.name,
        ownership_reason: trObj(data?.ownership_reason_detail),
        contract: data?.contract_detail?.description,
        hab_surface: fn(data?.hab_surface),
        total_land: fn(data?.total_land),
        associated_share: fn(data?.associated_share),
        _reg_number: data?.reg_number,
      };
    });
  };

  const { loaded, deleteBulkRealEstate, gotoCreatePath } = props;

  // Add filterableColumns configuration
  const filterableColumns = [
 
    {
      columnId: 'realestate_type',
      columnDataType: 'text',
    },
    {
      columnId: 'hab_surface',
      columnDataType: 'text',
    },
    {
      columnId: 'hab_surf_measure',
      columnDataType: 'text',
    },
    {
      columnId: 'total_land',
      columnDataType: 'text',
    },
    {
      columnId: 'tot_land_measure',
      columnDataType: 'text',
    },
    {
      columnId: 'associated_share',
      columnDataType: 'text',
    },
    {
      columnId: 'construction_year',
      columnDataType: 'text',
    },
    {
      columnId: 'cadastral_register',
      columnDataType: 'text',
    },
    {
      columnId: 'page',
      columnDataType: 'text',
    },
    {
      columnId: '_reg_number',
      columnDataType: 'text',
    },
    {
      columnId: 'address',
      columnDataType: 'text',
    },
    {
      columnId: 'ownership_reason',
      columnDataType: 'text',
    },
    
    {
      columnId: 'contract',
      columnDataType: 'text',
    },
    {
      columnId: 'valid_from',
      columnDataType: 'date',
    },
    {
      columnId: 'valid_to',
      columnDataType: 'date',
    },
   
  ];

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }
  console.log('filterableColumns', filterableColumns);

  return (
    <MyDataContentTemplate
      tableName="real_estate"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkRealEstate}
      filterableColumns={filterableColumns}
    />
  );
};

export default compose(
  withTranslation,
  withRealEstateInfo,
  withCustomRouter,
  withUserPreferences,
)(RealEstate);
